import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

Fancybox.defaults.l10n = {
  CLOSE: 'Schlie&szlig;en',
  NEXT: 'Weiter',
  PREV: 'Zurück',
  ERROR:
    'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
  TOGGLE_SLIDESHOW: 'Diaschau ',
  TOGGLE_FULLSCREEN: 'Vollbild',
  TOGGLE_THUMBS: 'Vorschaubilder',
  ITERATEZOOM: 'Zoom',
  DOWNLOAD: 'Herunterladen',
  SHARE: 'Teilen',
  ZOOM: 'Vergr&ouml;&szlig;ern',
}

Fancybox.bind('[data-fancybox]', {
  // Your custom options

  Toolbar: {
    enabled: true,
    display: {
      left: ['infobar'],
      middle: ['zoomIn', 'zoomOut', 'toggle1to1'],
      right: ['close'],
    },
  },

  Html: {
    closeBtn: true,
    videoAutoplay: false,
    videoTpl: `<video class="fancybox__html5video" preload="none" loop playsinline muted controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video>`,
  },
})
