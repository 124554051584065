<template>
  <div
    class="WatchListWidget-wrapper"
    :class="{
      'WatchListWidget-wrapper--empty': currentItem == undefined,
      'WatchListWidget-wrapper--hasPrev': widgetData.previous,
      'WatchListWidget-wrapper--hasNext': widgetData.next,
    }"
  >
    <a
      v-if="widgetData.previous"
      class="button"
      :href="widgetData.previous.url"
      :title="'Gehe zu ' + widgetData.previous.title"
    >
      <i class="las la-arrow-left"></i>
    </a>
    <div
      class="WatchListWidget-goto"
      :class="{ 'WatchListWidget-goto--loading': !widgetData }"
    >
      <label for="watchlistwidget" class="visually-hidden"
        >Merkliste Schnellnavigation</label
      >
      <select
        id="watchlistwidget"
        v-model="currentItem"
        :disabled="!widgetData"
        class="WatchListWidget-select"
        @change="goToWatchListItem()"
      >
        <option
          v-if="currentItem == undefined"
          disabled
          :value="undefined"
          aria-hidden="true"
        >
          Merkliste …
        </option>
        <option
          v-for="(item, index) in widgetData.items"
          :key="index"
          :value="item.id"
        >
          {{ item.title }}
        </option>
      </select>
      <i
        class="WatchListWidget-selectIcon las la-angle-down"
        aria-hidden="true"
      ></i>
    </div>
    <a
      v-if="widgetData.next"
      class="button"
      :href="widgetData.next.url"
      :title="'Gehe zu ' + widgetData.next.title"
    >
      <i class="las la-arrow-right"></i>
    </a>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    pageId: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      pending: false,
      currentItem: undefined,
      widgetData: false,
    }
  },
  mounted() {
    this.getWidgetData()
  },
  methods: {
    goToWatchListItem() {
      const targetItem = this.widgetData.items.find(
        (item) => item.id == this.currentItem,
      )

      if (targetItem) {
        window.location = targetItem.url
      }
    },
    getWidgetData() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      this.pending = true

      axios
        .post(
          document.jsVariables.apiBaseUrl + 'getWatchListDataForWidget',
          { pageId: this.pageId },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then((response) => {
          this.pending = false
          this.isInitial = false
          if (response.status == 200) {
            this.widgetData = response.data
            this.currentItem = this.widgetData.current.id
          }
        })
    },
  },
}
</script>
